.footer-container {
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.13);
  width: 100%;
  margin-top: 2em;
  position: relative;
}

.footer-container-small {
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.13);
  width: calc(100% - 300px);
  margin-top: 2em;
  position: relative;
  margin-left: 300px;
}

.footer-line {
  position: absolute;
  top: -4em;
  right: 6em;
  bottom: 3em;
  max-width: 400px;
  z-index: -1000;
}

.clickable-link {
  cursor: pointer;
  text-decoration: none;
}
.clickable-link:hover {
  text-decoration: underline;
}
