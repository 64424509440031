.create-event-button {
  width: 195px;
  height: 52px;
}

.tool-bar {
  width: 100%;
  padding: 10px;
}

.test-class {
  height: 500px;
}
.autocomplete-option {
  background-color: white;
  padding: 0.5rem;
}

.autocomplete-option:hover {
  background-color: #f5f5f5;
}
