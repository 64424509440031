.big-card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.MuiTypography-root {
  font-family: 'Ubuntu';
}

.big-image-container {
  position: relative;
  text-align: center;
  background-color: #000000;
  height: 50vh;
  top: 20vh;
  left: 10vh;
  width: 70vh;
  z-index: 3;
}

.big-image {
  height: 50vh;
  top: 20vh;
  left: 10vh;
  width: 70vh;
  box-shadow: inset 10px 10px 30px 0 #000000;
  z-index: 3;
}
.big-event-name {
  font-family: 'Ubuntu';
  position: absolute;
  bottom: 8px;
  left: 15px;
  width: 100%;
  text-align: left;
  font-size: large;
  color: #f0f0f0;
}

.big-eventcard-fader {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
  left: 0;
  height: 50vh;
  background: linear-gradient(0deg, grey, transparent 50%) bottom;
}

.big-time-box {
  display: flex;
  color: #434343;
  padding: 0px 0px 10px 0px;
  min-width: 288;
  max-width: 350;
  height: 80px;
}

.big-date {
  align-items: center;
  position: absolute;
  left: 30px;
  font-family: 'Ubuntu';
  border-right: 1.5px solid #959595;
  height: 70px;
  padding: 0 15px 10px 0;
}

.big-information {
  position: absolute;
  left: 34%;
}

.big-time-of-event {
  display: flex;
  align-items: center;
  size: 30px;
}
.big-time-of-event-img {
  max-height: 18px;
  max-width: 18px;
  padding: 0 8px 0 0;
}

.big-location-of-event {
  display: flex;
  align-items: center;
  size: 30px;
}

.big-location-of-event-img {
  max-height: 18px;
  max-width: 18px;
  padding: 0 8px 0 0;
}

.fix-font {
  font-family: 'Ubuntu';
}
.line {
  width: 75%;
}
.big-description-field {
  font-family: 'Ubuntu';
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 350;
  color: #838383;
  display: block;
  white-space: normal;
}
.LocationBox {
  position: absolute;
  top: 10em;
  left: 63em;
  padding: 10px;
  margin: 10px;
  width: 260px;
  z-index: 2;
  height: 275px;
}
.darkgreyBox {
  background-color: #434343;
  position: absolute;
  top: 30em;
  left: 98em;
  padding: 10px;
  margin: 10px;
  width: 260px;
  z-index: 2;
  height: 275px;
}
.big-blueLine {
  z-index: 1;
  position: absolute;
  top: 22em;
  right: 18em;
  width: 50vw;
  max-width: 900px;
}
