.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px !important;
  cursor: pointer;
  font-size: 20px;
  z-index: 10000;
}

.css-1kbrkji-MuiImageList-root {
  width: auto !important;
  border: 0.1px solid #acacac;
}
