.eventpopup-container {
  border-radius: 12px;
}

.submit-event-button {
  width: 144px;
  height: 42px;
}

.button-container {
  padding: 10px 0 30px 0;
}

.banner {
  position: relative;
  img {
    display: block;
  }
  .icon {
    position: absolute;
    top: 0;
    right: 5px;
  }
}

.cross-corner {
  position: absolute;
  right: 10px;
  top: 10px;
}
.cross-corner:hover {
  cursor: pointer;
}
