.previewSelectedImage {
  object-fit: 'cover';
  max-height: '290px';
  width: '100%';
  max-height: 338px;
  max-height: fit-content;
  border-radius: 8px;
}
.image-search-container {
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 4%);
}
.uploadFile {
  background-color: #d9d9d9;
  border-radius: 12px;
  padding: 0 20px 10px 20px;
}
.stockImageContainer {
  min-width: 200px;
}

.uploadImageContainer {
  border: 1px dashed rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  min-width: 200px;
}

.stock-image {
  border-radius: 1.9px;
  cursor: pointer;
}

.container-selected-image {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  .cancel-icon {
    position: absolute;
    top: 1px;
    right: 1px;
    cursor: pointer;
  }
  .cancel-icon-background {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 14px;
    height: 14px;
    border-radius: 12px;
    background-color: white;
  }
  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 350px;
    display: block;
    width: 100%;
  }
}
.listImage {
  border-radius: 8px;
  cursor: pointer;
}

.uploadImageText {
  color: #666666;
  font-family: 'ubuntu';
  font-size: 16px;
}
