.container {
  border: 2px solid green;
}

.item {
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid black;
}

.blue-line {
  z-index: -1000;
  position: absolute;
  top: 8em;
  width: 60vw;
  max-width: 900px;
}
