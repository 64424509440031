$borderRadius: 12px;
.image-list-itembar {
  border-radius: $borderRadius;
  height: 100%;
  text-align: center;
  opacity: 0.8;
}

.image-list-itembar:hover {
  opacity: 1;
  cursor: pointer;
}

.location-card {
  img {
    border-radius: $borderRadius;
    max-height: 170px;
  }
}
