.card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.MuiTypography-root {
  font-family: 'Ubuntu';
}

.image-container {
  position: relative;
  top: 0;
  text-align: center;
  background-color: #000000;
  height: 179px;
}

.image {
  height: 179px;
  top: 0;
  box-shadow: inset 10px 10px 30px 0 #000000;
  z-index: 0;
}
.event-name {
  font-family: 'Ubuntu';
  position: absolute;
  bottom: 8px;
  left: 15px;
  text-align: left;
  font-size: large;
  color: #f0f0f0;
}

.eventcard-fader {
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0;
  height: 179px;
  background: linear-gradient(0deg, black, transparent 50%) bottom;
}

.date {
  align-items: center;
  position: absolute;
  left: 30px;
  font-family: 'Ubuntu';
  border-right: 1.5px solid #959595;
  height: 70px;
  padding: 0 15px 10px 0;
}

.information {
  position: absolute;
  left: 34%;
}

.time-of-event {
  display: flex;
  align-items: center;
  size: 30px;
}
.time-of-event-img {
  max-height: 18px;
  max-width: 18px;
  padding: 0 8px 0 0;
}

.location-of-event {
  display: flex;
  align-items: center;
  size: 30px;
}

.location-of-event-img {
  max-height: 18px;
  max-width: 18px;
  padding: 0 8px 0 0;
}

.fix-font {
  font-family: 'Ubuntu';
}
.description-field {
  font-family: 'Ubuntu';
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 350;
  color: #838383;
  display: block;
  white-space: normal;
}
.groupName {
  max-width: 0px;
}