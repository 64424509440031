.header {
  background-color: white;
}

.header-content {
  margin: auto;
  background: white;
}

.nav-button{
  width: 10em;
  height: 3.5em;
}
.nav-button-active {
  box-shadow: inset 0px -5px 0px 0px  !important;
}
.nav-button-active:hover{
  box-shadow: inset 0px -5px 0px 0px  !important;
}