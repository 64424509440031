// --- Used for Calendar ---
.calendar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}

// --- Used for GridCalendar ---
.cell {
  border: 0.5px solid darkgray;
}

.current {
  background: #d3edd3;
}
.otherMonth {
  background: #f7f7f7;
  color: #bfbfbf;
}
.mobileEvent {
  cursor: pointer;
}

.miniEventContainer {
  margin-left: 3px;
  margin-right: 3px;
  padding-right: 3px;
  height: 105px;
  overflow: auto;
}

.miniEvent {
  color: white;
  padding: 2px;
  font-size: 0.8em;
  cursor: pointer;
  height: 28px;
  margin-bottom: 4px;
  overflow: hidden;
  border-radius: 4px;
}

.largeEvent {
  padding: 3px;
  font-size: 0.8em;
  cursor: pointer;
  height: 100%;
  color: white;
}

.days {
  width: 14.2857%;
  height: auto;
}

.dialogBox {
  width: 500px;
  margin: auto;
}

// --- Used for Navigation ---
.navigation {
  width: 100%;
  padding: 10px 8px 14px 8px;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
}

.navigation-option {
  display: inline-block;
  width: 33.33%;
}

.navigation-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation .monthAndYear {
  text-align: center;
  font-weight: bold;
}

.navigation .monthAndYear a {
  text-decoration: none;
  display: inline-block;
  padding: 0 10px;
  background: #27bc27;
  border-radius: 5px;
  color: white;
  margin-left: 10px;
  text-shadow: 0 0px 2px #727272;
}

.navigation .back,
.navigation .forward {
  cursor: pointer;
  font-size: 0.7em;
  color: grey;
}

.navigation .back {
  text-align: left;
}

.navigation .forward {
  text-align: right;
}
