.image-container {
  position: relative;
  height: 181px;
}

.eventcard-fader {
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0;
  height: 181px;
  background: linear-gradient(0deg, black, transparent 50%) bottom;
}
.attending-tag {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  opacity: 0.8;
  color: white;
  font-family: Ubuntu, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-name {
  position: absolute;
  bottom: 8px;
  left: 15px;
  text-align: left;
  font-size: large;
  color: #f0f0f0;
}

.center-content {
  display: flex;
  align-items: center;
}

.icon-size {
  max-height: 18px;
  max-width: 18px;
  padding-right: 5px;
}

.description-field {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-weight: 350;
  color: #838383;
}

.year-color {
  color: #c0bfbf;
  font-size: small;
}

.logo-background {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.28) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5%;
  top: 5%;
}
.card:hover {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}