.groupcard-container {
  position: relative;
  height: 100%;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.groupcard-content-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 101%;
  background: linear-gradient(0deg, black, transparent 70%) bottom;
}

.groupcard-info {
  position: absolute;
  bottom: 0;
  height: auto;
  padding: 5px 10px 5px 10px;
}

.groupcard-name {
  color: #f0f0f0;
}

.groupcard-description {
  color: #838383;
}

.imagePart-container {
  position: relative;
  height: 170px;
  width: 100%;
  justify-content: center;
  align-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image-shadow {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, black, transparent 60%) bottom;
}

.group-name {
  position: absolute;
  bottom: 10px;
  left: 15px;
  text-align: left;
  color: #f0f0f0;
}

.group-description {
  color: #838383;
  text-align: left;
}

.groupcard-button {
  width: 90%;
}

.groupcard-allignment-container-left {
  max-height: 450px;
  @media only screen and (max-width: 600px) {
    max-height: 215px;
  }
}
