.eventimage-image-container {
  position: relative;
  height: 24rem;
  display: flex;
}

.deadline-banner {
  position: absolute;
  top: 8px;
  right: 0;
  left: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventimage-image {
  border-radius: 8px;
}

.eventimage-fader {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  background: linear-gradient(0deg, black, transparent 50%) bottom;
  border-radius: 8px;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.eventpage-footer {
  animation: 0.75s ease-out 0s 1 slideInFromBottom;

  position: sticky;
  bottom: 0;
  border-radius: 8px;
}
.eventpage-footer-white {
  position: sticky;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  z-index: 0;
  background-color: #ffff;
}

.join-event-button-container {
  position: absolute;
  right: 3rem;
  bottom: 0.35rem;
}

.join-event-button-button {
  position: relative;
  left: 0;
  top: 0;
}
