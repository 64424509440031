
  .loading-icon {
    position: absolute;
    top: 0px;
    width: 100%;
    margin-top: 56px;
    align-items: center;
    justify-content: center;
    color: var(--color-text-lighter);
  }
  .progressing {
    top: -40px;
     transition: top 0.3s ease-in-out
  }