/* Index */

image-list {
  width: 100%;
}
.carousel-container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.carousel-root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.carousel * {
  display: inline-block;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  display: flex;
  align-items: center;
}

.carousel.carousel-slider .control-arrow {
  padding: 7rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.carousel .carousel-status {
  position: absolute;
  top: 60px;
  right: 15px;
  padding: 5px;
  font-size: 15px;
}

.ImageListItem:hover img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* ImageGrid */

.image-list {
  display: grid;
  gap: 8px;
}

.ImageListItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-stepper-container {
  width: 100% !important;
}

.HoverImage {
  transition: transform 0.3s ease;
}

.HoverImage:hover {
  transform: scale(1);
}

/* DeleteImageCarousel */
#panel1-header {
  justify-content: center;
  background-color: rgba(177, 156, 75, 0.2);
}

.delete-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  height: 40rem;
  gap: 1.25rem;
  justify-content: center;
  overflow-y: auto;
  border-radius: 1rem;
}

.deleteCarousel-content {
  padding-bottom: 3rem;
  border-radius: 5px;
}

.delete-cards {
  box-shadow: 0 1rem 1.1rem 0.18rem rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
}

.card-images {
  width: 100%;
  height: 15rem;
  object-fit: contain;
  border-radius: 0.313rem;
  margin-top: 1rem;
}
