
.membercard-image {
    min-height: 70px;
    min-width: 70px;
    object-fit: cover;
    display: inline;
    align-self: center;
}

.membercard-no-image {
    margin-top: -12%;
    align-self: center;
}

.membercard-image-container {
    align-items: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin-top: 3%;
    overflow: hidden;
}

.membercard-text-container{
    overflow: hidden;
    height: 30px;
}


.membercard-text-fix {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12rem;
}
